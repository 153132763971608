<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">FLIGHT OPEN</div>
      </div>
      <div class="box-S4">
        <p>
          Departure From :
          {{ ap_name }}
        </p>
      </div>
    </div>
    <CompListCheckin :Service="'active'" :ap_id="this.$route.params.id" />
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import CompListCheckin from "../components/checkin-form/CompListCheckin";
export default {
  data: () => ({
    itemsListDeparture: [],
    selectDeparture: {},
    dp_id: null,
    ap_name: "",
  }),

  components: {
    CompListCheckin,
  },
  async mounted() {
    await this.renderListDeparture();
  },
  methods: {
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        this.selectDeparture = {
          ap_id: this.itemsListDeparture.filter(
            (x) => x.ap_id == this.$route.params.id
          )[0].ap_id,
          ap_name: this.itemsListDeparture.filter(
            (x) => x.ap_id == this.$route.params.id
          )[0].ap_name,
        };
        this.ap_name = this.itemsListDeparture.filter((x) => x.ap_id == this.$route.params.id)[0].ap_name;
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
  },
};
</script>
<style>
</style>