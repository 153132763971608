<template>
  <div>
    <div id="checkinList" class="wrap-Main">
      <div class="box-S4 flex-item-warp noPadding">
        <div class="box-S4" v-for="item in itemsListCheckin" :key="item.f_id">
          <div class="B-carddetail" style="border-width:2px;border-style:solid"
            :style="[item.departairport == 'VTSH' ? { 'border-color': 'black' } : { 'border-color': '#f7f7f7;' }]">
            <div class="b-dateTime">
              <div class="date T-size-20 SemiBold">
                <span class="I-calendar"></span>
                {{ item.ap_depart_date | yyyyMMdd }}
              </div>
              <div class="time T-size-20 SemiBold">
                <span class="I-time"></span>
                {{ item.ap_depart_date | HHmm }}
              </div>
            </div>
            <div class="b-textData">
              <div class="t-owner">
                Flight No :
                <span>{{ item.f_flight_no }}</span>
              </div>
            </div>
            <!-- <div class="b-textData">
              <div class="t-owner">
                Departure From :
                <span>{{ item.departairport }}</span>
              </div>
            </div>
            <div class="b-textData">
              <div class="t-owner">
                Destination :
                <span>{{ item.deestairport }}</span>
              </div>
            </div> -->
            <div class="b-textData">
              <div class="t-owner">
                Route : {{ item.route }}
              </div>
            </div>
            <div class="b-textData">
              <div class="t-owner">
                Passenger :
                <span>{{ item.cpo | setzoro }}</span>
              </div>
            </div>
            <div class="b-textData">
              <div class="t-owner">
                Customer :
                <!-- <span>{{item.c_name}}</span> -->
                <span>{{ item.o_name }}</span>
              </div>
              <div class="t-aircraft">

              </div>
            </div>

            <div class="line red"></div>
            <div class="b-tagBtn">
              <div class="b-tag">
                <div v-if="Service == 'active'" class="tag T-size-12 tagStatusActive">
                  Status : {{ item.fs_name.toUpperCase() }}
                </div>
                <div v-else-if="Service == 'close'" class="tag T-size-12 tagStatusClose">
                  Status : {{ item.fs_name }}
                </div>
                <!-- เปลี่ยนคราสตาม Status tagStatusActive , tagStatusClose , tagStatusTakeoff -->
                <div v-else class="tag T-size-12 tagStatusTakeoff">
                  Status : {{ item.fs_name }}
                </div>
              </div>
              <v-btn class="theme-btn-even" :to="{ name: 'MubadalaForCustomerDetail', params: { id: item.f_id } }">
                <span>SELECT FLIGHT</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import fillerdate from "../../utils/filterdate";
import { startOfDay, endOfDay, format, addHours } from "date-fns";
export default {
  data: () => ({
    itemsListCheckin: [],
    itemsListCheckinbeta: [],
    ref_id: "",
  }),
  watch: {
    // This would be called anytime the value of title changes
    ap_id(newValue, oldValue) {
      this.itemsListCheckin = this.itemsListCheckinbeta.filter((x) => x.ap_id == this.ap_id)
      this.ASCOrderbyDepartDate();
    }
  },
  props: ["Service", "ap_id"],
  async mounted() {
    switch (this.Service) {
      case "active":
      case "close":
        this.renderListCheckin();
        break;
      case "takeoff":
        //รันครั้งแรกตอนเรียก component
        let dateNow = format(startOfDay(new Date()), "yyyy-MM-dd");
        this.renderListCheckinbyDate(dateNow);
        break;
      default:
        break;
    }
  },
  methods: {
    async renderListCheckin() {
      const q = {};
      q.retire = 0;
      var idStatus = this.Service == "active" ? 3 : 4;
      q.fs_id = idStatus;
      q.o_id = 4;
      try {
        var res = await feathersClientUOA
          .service("viewflight")
          .find({ query: q });
        this.itemsListCheckinbeta = res.data;
        this.itemsListCheckin = this.itemsListCheckinbeta.filter((x) => x.ap_id == this.ap_id)
        await this.ASCOrderbyDepartDate();
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถติดต่อ server ได้");
      }
    },
    async renderListCheckinbyDate(dataDate) {
      var idStatus = 5;
      var DateStart = format(startOfDay(new Date(dataDate)), "yyyy-MM-dd HH:mm:ss");
      var DateEnd = format(endOfDay(new Date(dataDate)), "yyyy-MM-dd HH:mm:ss");
      const q = {};
      q.retire = 0;
      q.fs_id = {
        $in: [5, 6]
      };
      q.ap_depart_date = {
        $gte: DateStart,
        $lte: DateEnd,
      };
      try {
        var res = await feathersClientUOA
          .service("viewflight")
          .find({ query: q });
        if (res.data != []) {
          this.itemsListCheckinbeta = res.data;
          this.itemsListCheckin = this.itemsListCheckinbeta.filter((x) => x.ap_id == this.ap_id)
          await this.ASCOrderbyDepartDate();
        }
      } catch (error) {
        console.log(error);
      }
    },
    ASCOrderbyDepartDate() {
      this.itemsListCheckin.sort(function (a, b) {
        // let startDate = a.ap_depart_date.substring(0, 10);
        // let startTime = a.ap_depart_date.substring(11, 16);

        // let endDate = b.ap_depart_date.substring(0, 10);
        // let endTime = b.ap_depart_date.substring(11, 16);

        // let start = new Date(startDate + " " + startTime);
        // let end = new Date(endDate + " " + endTime);

        let start = new Date(a.ap_depart_date);
        let end = new Date(b.ap_depart_date);

        return start - end;
      });
    },
  },
  filters: {
    setzoro: function (value) {
      return value === null ? 0 : value;
    },
  },
};
</script>

<style>
</style>